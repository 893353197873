@font-face {
    font-family: 'NanumMyeongjo';
    src: url("../font/NanumMyeongjo.ttf");
}

.main-container {
    width: 100%;
    height: 700px;
    overflow: hidden;
    position: relative;
}

.main-container img {
    width: 100%; 
    height: 100%;
    object-fit: cover;
    background-size:cover;
    background-position:50%;

    filter: brightness(50%);
    position: fixed;
    z-index: -1;
}

.main-container label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #FFFFFF;

    font-size: 5vh;
    letter-spacing: 0.25rem;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: #E8E8E8;
    text-underline-offset: 10px;
}

.company-nav {
    top: 80px;
    padding: 0;
    min-height: 80px;
    /* background-color: #FFFFFF; */
}
.company-nav div {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: rgba(255, 255, 255, 0.2) solid 0.5px;
}
.company-nav div .nav-link {
    font-size: 18px;
    /* color: #0070c0; */
    color: #FFFFFF;
    width: 25%;
    padding: 25px 5px 24px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* border-bottom: #0070c0 solid 5px; */
    border-radius: 0px;
    border-bottom: #0070c0 solid 5px;
}
.company-nav div .nav-link:hover {
    background: rgba(255, 255, 255, 0.2);
    border-bottom: #33aaff solid 5px;
    z-index: 2;
    /* box-shadow: 0 0 0 3px #4872f4 inset; */
    /* box-sizing: border-box; */
}
.nav-pills .nav-link.active {
    background-color: transparent;
}

.sub-title {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-title label {
    font-family: 'NanumMyeongjo';
    justify-content: center;
    display: flex;
    align-items: center;

    font-size: 3rem;
}
.sub-title p {
    justify-content: center;
    display: flex;
    align-items: center;

    letter-spacing: 0.25rem;
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-decoration-color: #E8E8E8;
    text-underline-offset: 10px;
}

.greeting_content {
    display: flex;
    flex-direction: column;
}
/* .greeting_content strong::after {
    content: '';
    display: block;
    position: absolute;
    width: 80px;
    height: 1px;
    background: #999;
    bottom: 0;
    left: 0;
} */
.greeting_content div {
    margin-bottom: 15px;
}

.location_info {
    /* width: 25%; */
    width: 100%;
    float: left;
    text-align: center;
    height: 187px;
}
.location_wrap {
    margin-top: 50px;
    border-right: 1px solid #eee;
}
.location_wrap:last-child {
    border-right: 0;
}
.location_info > div {
    width: 120px;
    height: 120px;
    display: inline-block;
    font-size: 32px;
    color: #fff;
    border-radius: 50%;
    background: #6595ca;
    padding: 32px;
}
.location_info img {
    height: 35px;
    filter: invert(100%);
    /* font-size: 32px;
    color: #fff; */
}
.location_info h5 {
    font-weight: 700;
    margin: -41px 0 50px;
    font-size: 15px;
    color: #fff;
}
.location_info p {
    font-size: 15px;
    margin-bottom: 0;
}

.production_wrap {
    margin: 0 0 30px;
    padding: 45px 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 2px solid #333;
}

.product_content {
    display: flex;
    flex-direction: column;
}
.product_content label {
    font-size: 18px;
    margin-bottom: 20px;
}
.product_content label:first-child{
    font-weight: 700;
    letter-spacing: 0;
    border-bottom: 1px solid #999;
    display: inline-block;
    padding-bottom: 2px;
}

.product_img {
    display: flex;
    overflow: auto;
}
.product_img img {
    height: 250px;
    width: auto;
}

#greeting {
    width: 100%;
    overflow: hidden;
    padding: 150px 0px;
}

#workplace {
    width: 100%;
    overflow: hidden;
    padding: 150px 0px;

    background-color: #f8f8f8;
}

#location {
    width: 100%;
    overflow: hidden;
    padding: 150px 0px;
}

#production {
    width: 100%;
    overflow: hidden;
    padding: 150px 0px;

    /* height: 2000px; */
    background-color: #f8f8f8;
}