table {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    border-collapse: collapse;
}

th {
    background-color: #f2f2f2 !important;
}

.table-wrap {
    width: 100%;
    overflow: auto;
}

.main-container {
    width: 100%;
    height: 700px;
    overflow: hidden;
    position: relative;
}

.main-container img {
    width: 100%; 
    height: 100%;
    object-fit: cover;
    background-size:cover;
    background-position:50%;

    filter: brightness(50%);
    position: fixed;
    z-index: -1;
}

.main-container label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #FFFFFF;

    font-size: 5vh;
    letter-spacing: 0.25rem;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: #E8E8E8;
    text-underline-offset: 10px;
}

.first_wrap {
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
}

.second_wrap {
    display: flex;
    justify-content: center;
    background-color: #f8f8f8;
}

.in_wrap {
    margin: 5vw 10vw;
    width: 100%;
    overflow: hidden;
}

.prod-container {
    margin: 100px 0px;
}

.prod-title {
    padding: 30px 0px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.prod-title label {
    font-size: 25px;
    font-weight: 500;
    margin: 0px 10px;
}

.prod-title img {
    filter: invert(87%) sepia(0%) saturate(0%) hue-rotate(256deg) brightness(88%) contrast(90%);
}

.example-wrap {
    display: flex;
    justify-content: center;
    margin: 50px 0px;
}
.example-wrap div {
    display: flex;
    flex-direction: column;
    margin: 0px 2vw;
}
.example-wrap div > img {
    height: 230px;
    width: auto;
}