@font-face {
  font-family: 'LINESeedKR-Bd';
  src: url("../font/LINESeedKR-Bd.ttf");
}

@font-face {
  font-family: 'NanumGothic_Bd';
  src: url("../font/NanumGothicBold.ttf");
}

@font-face {
  font-family: 'NanumSquareNeo-bRg';
  src: url("../font/NanumSquareNeo-bRg.ttf");
}

@font-face {
  font-family: 'Nanum_Gothic';
  src: url("https://fonts.googleapis.com/css?family=Nanum+Gothic");
}

.header {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  min-height: 80px;
}
.header:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.headerLogo {
  height: 50px;
  width: auto;
  margin: 0px 20px;
}

.headerMenu div, .headerMenu a {
  display: inline-block;
  position: relative;
}

.headerMenu div, .headerMenu a::after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 0;
    height: 1px;
    background: #fff;
    transition: all 0.2s ease-in-out;
}

.headerMenu a.nav-link {
  font-size: 20px;
  font-family: 'Nanum_Gothic';
  font-weight: 600;
  margin: 0px 20px;
}
/* .headerMenu a.nav-link::after {
  transition: .25s;
  content: '';
  position: absolute;
  left: 0;
  bottom: 37px;
  display: block;
  height: 1px;
  width: 0%;
  background: #ee3349;
} */

.carousel_main {
  width: 100%;
  height: 100vh;
  /* height: calc(100vh - 90px); */
  position: relative;
}

.carousel_img {
  width: 100%; 
  height: 100%;
  object-fit: cover;
  background-size:cover;
  background-position:50%;
}

.footer-info {
  padding: 35px 0 40px;
  background-color: #3b3b3f;
  color: #ccc;

  font-size: 18px;
  font-weight: 300;
}

/* .footer-wrap {
  height: 60px;
} */

.footer-info ul {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 0;
  padding-left: 30px;
}

.footer-info ul li {
  list-style: none;
  display: inline-block;
  font-size: 12px;
  margin-right: 15px;
  line-height: 1.7em;
  letter-spacing: 0.03em;
  padding-bottom: 1px;
}

.footer-info ul li a {
  color: inherit;
  text-decoration: none;
}

.footer-info img {
  display: block;
  max-width: 100%;
  height: 65px;
  /* height: 80px; */
  filter: invert(87%) sepia(0%) saturate(0%) hue-rotate(256deg) brightness(88%) contrast(90%);

  /* justify-content: center;
  display: flex; */
}
